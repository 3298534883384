<template>

  <div id="hello">
    <img id="logo" src="/logo.svg" />
  </div>

  <div id="content">
    <router-view />
  </div>

  <div id="bottom">
    <div id="social">
      <a href="mailto:hello@emblem.games">
        <at-icon :size="30" />
      </a>
      <a href="https://store.steampowered.com/curator/44837605">
        <steam-icon :size="30" />
      </a>
      <!-- <a href="#">
        <instagram-icon :size="30" />
      </a> -->
      <!-- <a href="#">
        <facebook-icon :size="30" />
      </a> -->
      <a href="https://twitter.com/EmblemGamesTeam">
        <twitter-icon :size="30" />
      </a>
      <a href="https://www.twitch.tv/emblemgamestudio">
        <twitch-icon :size="30" />
      </a>
      <a href="https://discord.gg/kvADVD7CNR">
        <img src="discord.svg" width="28" height="28" />
      </a>
      <a href="https://vk.com/emblemgames">
        <img src="vk.svg" width="28" height="28" />
      </a>
      <a href="https://www.reddit.com/r/Emblem_Games_Studio/">
        <reddit-icon :size="30" />
      </a>
    </div>

    <!-- 2023 &ndash; 2024 &copy; Emblem Games<br />
    <a href="mailto:hello@emblem.games">hello@emblem.games</a> -->
  </div>
</template>

<script>
import AtIcon from 'vue-material-design-icons/At.vue';
import InstagramIcon from 'vue-material-design-icons/Instagram.vue';
import FacebookIcon from 'vue-material-design-icons/Facebook.vue';
import TwitterIcon from 'vue-material-design-icons/Twitter.vue';
import SteamIcon from 'vue-material-design-icons/Steam.vue';
import TwitchIcon from 'vue-material-design-icons/Twitch.vue';
import RedditIcon from 'vue-material-design-icons/Reddit.vue';

export default {
  name: 'App',
  components: {
    AtIcon,
    InstagramIcon,
    FacebookIcon,
    TwitterIcon,
    SteamIcon,
    TwitchIcon,
    RedditIcon,
  },
  computed: {
    currentRouteName() {
        return this.$route.name;
    },
  },
}
</script>

<style lang="less">
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  border: 0;
}
html {
  background: transparent;
}
body {
  text-align: center;
  margin: 0;
  padding: 0;
  font-family: Helvetica, sans-serif;
  font-size: 17px;
  background-color: #d8cab5;
  color: #262028;
}
h1, h2 {
  font-weight: 300;
  margin-bottom: 20px;
}

h1 {
  font-size: 50px;
}
h2 {
  font-size: 40px;
}
a {
  color: #7DB9B6;
}
p {
  margin-bottom: 20px;
  line-height: 140%;
}

div#hello {
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
  // padding: 120px;
  img#logo {
    width: 220px;
    height: 100px;
  }
}

div#content {
  width: 760px;
  margin: auto;
  text-align: left;
  // outline: 1px dotted red;
}
div#bottom {
  padding-top: 40px;
  padding-bottom: 60px;
  line-height: 140%;
  a {
    color: #262028;
  }
  div#social {
    padding-bottom: 20px;
    a {
      margin: 0 4px;
    }
  }
}

@media only screen and (max-width: 768px) {
  body {
    font-size: 20px;
  }
  div#content {
    width: 100%;
    padding: 0 40px;
  }
}

</style>
